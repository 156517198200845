




































































































































import {defineComponent, ref, useContext, watch} from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import {
  SfInput,
  SfButton,
  SfCheckbox,
  SfLoader,
} from '@storefront-ui/vue';
import { required, email, alpha } from 'vee-validate/dist/rules';
import { customerPasswordRegExp, invalidPasswordMsg } from '~/modules/customer/helpers/passwordValidation';
import { RegisterFormFields, FormName } from '~/modules/customer/components/LoginModal/forms/types';

extend('email', {
  ...email,
  message: 'Invalid email',
});

extend('required', {
  ...required,
  message: 'This field is required',
});

extend('password', {
  message: invalidPasswordMsg,
  validate: (value: string) => customerPasswordRegExp.test(value),
});

extend('alpha', {
  validate(value) {
    return {
      valid: /^[a-zA-ZÀ-Ÿ0-9\s., &#/!\'-]+$/.test(value)
    };
  },
  message: 'Use only alphabetic letters'
});

export default defineComponent({
  components: {
    SfInput,
    SfButton,
    SfCheckbox,
    SfLoader,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    form: {
      type: Object as PropType<RegisterFormFields>,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    showRecaptcha: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const {
      app: { $gtm }
    } = useContext();
    const formCopy = ref<RegisterFormFields>();
    watch(() => props.form, (newForm) => { formCopy.value = { ...newForm }; }, { immediate: true, deep: true });
    const changeForm = (formToNavigateTo: FormName) => {
      emit('change-form', formToNavigateTo);
    };
    const getClickRegisterDetails = () => {
      $gtm.push({
        event: 'clickAccountCreation'
      })
    }

    return {
      formCopy,
      changeForm,
      getClickRegisterDetails
    };
  },
});
